<template>
  <div class="app-container">
    <el-card shadow="never" class="flex" v-loading="ruleLoading">
      <div class="fc-e">注意：以下各项设置为0时，表示不进行限制。</div>
      <el-form ref="form" :model="form" :rules="rules" label-suffix=":" label-position="right" hide-required-asterisk label-width="96px" style="margin-top: 20px;">
        <div class="c h">
          <el-form-item label="零售转单率(小于等于)" prop="retailRate" label-width="160px">
            <percent-input :base="100" v-model.trim="form.retailRate" style="width:150px;" />
          </el-form-item>
          <el-form-item label="合格范围" prop="retailRateValidBeg">
            <percent-input :base="100" v-model.trim="form.retailRateValidBeg" style="width:150px;" />
          </el-form-item>
          <div style="height:32px;" class="padding-0-10">至</div>
          <el-form-item prop="retailRateValidEnd" label-width="0px">
            <percent-input :base="100" v-model.trim="form.retailRateValidEnd" style="width:150px;" />
          </el-form-item>
        </div>
        <div class="c h">
          <el-form-item label="到货入库率(小于等于)" prop="invoiceRate" label-width="160px">
            <percent-input :base="100" v-model.trim="form.invoiceRate" style="width:150px;" />
          </el-form-item>
          <el-form-item label="合格范围" prop="invoiceRateValidBeg">
            <percent-input :base="100" v-model.trim="form.invoiceRateValidBeg" style="width:150px;" />
          </el-form-item>
          <div style="height:32px;" class="padding-0-10">至</div>
          <el-form-item prop="invoiceRateValidEnd" label-width="0px">
            <percent-input :base="100" v-model.trim="form.invoiceRateValidEnd" style="width:150px;" />
          </el-form-item>
        </div>
        <div class="c h">
          <el-form-item label="发货及时率(小于等于)" prop="deliveryRate" label-width="160px">
            <percent-input :base="100" v-model.trim="form.deliveryRate" style="width:150px;" />
          </el-form-item>
          <el-form-item label="合格范围" prop="deliveryRateValidBeg">
            <percent-input :base="100" v-model.trim="form.deliveryRateValidBeg" style="width:150px;" />
          </el-form-item>
          <div style="height:32px;" class="padding-0-10">至</div>
          <el-form-item prop="deliveryRateValidEnd" label-width="0px">
            <percent-input :base="100" v-model.trim="form.deliveryRateValidEnd" style="width:150px;" />
          </el-form-item>
        </div>
        <el-form-item>
          <el-button :loading="saving" type="primary" @click="doSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      pageDealer: 0,
      sizeDealer: 10,
      totalElements: null,
      dialog: false,
      loading: false,
      addLoading: false,
      doSaveLoading: false,
      delLoading: false,
      times: {
        dayTimes: null,
      },
      dataDealer: [], //指定经销商
      buyLimit: {
        id: null,
      },
      current: null,
      ruleLoading: false,
      saving: false,
      form: {
        deliveryRate: 0, //发货
        invoiceRate: 0, //到货
        retailRate: 0,
        retailRateValidBeg: 0,
        retailRateValidEnd: 0,
        invoiceRateValidBeg: 0,
        invoiceRateValidEnd: 0,
        deliveryRateValidBeg: 0,
        deliveryRateValidEnd: 0,
      },
      rules: {
        retailRate: [
          { required: true, message: "请输入零售转单率", trigger: "blur" },
        ],
        retailRateValidBeg: [
          {
            required: true,
            message: "请输入零售转单率合格范围",
            trigger: "blur",
          },
        ],
        retailRateValidEnd: [
          {
            required: true,
            message: "请输入零售转单率合格范围",
            trigger: "blur",
          },
        ],
        invoiceRate: [
          { required: true, message: "请输入到货入库率", trigger: "blur" },
        ],
        invoiceRateValidBeg: [
          {
            required: true,
            message: "请输入到货入库率合格范围",
            trigger: "blur",
          },
        ],
        invoiceRateValidEnd: [
          {
            required: true,
            message: "请输入到货入库率合格范围",
            trigger: "blur",
          },
        ],

        deliveryRate: [
          { required: true, message: "请输入发货及时率", trigger: "blur" },
        ],
        deliveryRateValidBeg: [
          {
            required: true,
            message: "请输入发货及时率合格范围",
            trigger: "blur",
          },
        ],
        deliveryRateValidEnd: [
          {
            required: true,
            message: "请输入发货及时率合格范围",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.load();
  },
  methods: {
    pageChangeDealer(e) {
      this.pageDealer = e - 1;
      this.loadOder();
    },
    sizeChangeDealer(e) {
      this.pageDealer = 0;
      this.sizeDealer = e;
      this.loadOder();
    },
    // 查询
    load() {
      this.ruleLoading = true;
      request({
        url: "/api/performanceConfig",
        method: "get",
      })
        .then((res) => {
          this.form = Object.assign(
            res.content[0] || {
              deliveryRate: 0, //发货
              invoiceRate: 0, //到货
              retailRate: 0,
              retailRateValidBeg: 0,
              retailRateValidEnd: 0,
              invoiceRateValidBeg: 0,
              invoiceRateValidEnd: 0,
              deliveryRateValidBeg: 0,
              deliveryRateValidEnd: 0,
            }
          );
        })
        .finally((_) => {
          this.ruleLoading = false;
        });
    },
    doSave() {
      if (this.times.id) {
        this.doSaveLoading = true;
        request({
          url: "api/performanceBuyLimit",
          method: "put",
          data: {
            buyTimes: this.times.dayTimes,
            id: this.times.id,
          },
        })
          .then((res) => {
            this.addLoading = false;
            this.$notify({
              title: "修改成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.doSaveLoading = false;
          });
      } else {
        this.doSaveLoading = true;
        request({
          url: "api/performanceBuyLimit",
          method: "post",
          data: {
            buyTimes: this.times.dayTimes,
          },
        })
          .then((res) => {
            this.addLoading = false;
            this.$notify({
              title: "保存成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.doSaveLoading = false;
          });
      }
    },
    loadOder() {
      this.ruleLoading = true;
      request({
        url: "/api/performanceBuyLimitForAll",
        method: "get",
      })
        .then((res) => {
          if (res) {
            this.times.id = res.id;
            this.times.dayTimes = Object.assign(res.buyTimes || 0);
          }
        })
        .finally((_) => {
          this.ruleLoading = false;
        });
      let params = {
        page: this.pageDealer,
        size: this.sizeDealer,
        sort: "createAt,desc",
      };
      request({
        url: "/api/performanceBuyLimit",
        method: "get",
        params,
      }).then((res) => {
        if (res) {
          this.dataDealer = res.content;
          this.totalElements = res.totalElements;
        }
      });
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addLoading = true;
          if (this.form.id) {
            // 统一设置
            let data = {
              deliveryRate: this.form.deliveryRate,
              invoiceRate: this.form.invoiceRate,
              retailRate: this.form.retailRate,
              retailRateValidBeg: this.form.retailRateValidBeg,
              retailRateValidEnd: this.form.retailRateValidEnd,
              invoiceRateValidBeg: this.form.invoiceRateValidBeg,
              invoiceRateValidEnd: this.form.invoiceRateValidEnd,
              deliveryRateValidBeg: this.form.deliveryRateValidBeg,
              deliveryRateValidEnd: this.form.deliveryRateValidEnd,
              id: this.form.id,
            };
            request({
              url: "api/performanceConfig",
              method: "put",
              data: data,
            })
              .then((res) => {
                this.$notify({
                  title: "修改成功",
                  type: "success",
                  duration: 2500,
                });
              })
              .finally((_) => {
                this.addLoading = false;
              });
          } else {
            // 统一设置
            let data = {
              deliveryRate: this.form.deliveryRate,
              invoiceRate: this.form.invoiceRate,
              retailRate: this.form.retailRate,
              retailRateValidBeg: this.form.retailRateValidBeg,
              retailRateValidEnd: this.form.retailRateValidEnd,
              invoiceRateValidBeg: this.form.invoiceRateValidBeg,
              invoiceRateValidEnd: this.form.invoiceRateValidEnd,
              deliveryRateValidBeg: this.form.deliveryRateValidBeg,
              deliveryRateValidEnd: this.form.deliveryRateValidEnd,
            };
            request({
              url: "api/performanceConfig",
              method: "post",
              data: data,
            })
              .then((res) => {
                this.$notify({
                  title: "保存成功",
                  type: "success",
                  duration: 2500,
                });
              })
              .finally((_) => {
                this.addLoading = false;
              });
          }
        }
      });
    },
  },
};
</script>